import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { setLoading } from '../store/slices/loaderSlice';
import { CarouselStep } from '../utils/CarouselSteps';
import { Product } from '../models/Product';
import { Professional } from '../models/Professional';
import ProductDataService from '../services/product.dataservice';
import ProfessionalDataService from '../services/professional.dataservice';
import ProductList from '../components/Order/ProductList';
import ProfessionalList from '../components/Order/ProfessionalList';
import ReferenceYear from '../components/Order/ReferenceYear';
import DiscountSelection from '../components/Order/DiscountSelection';
import UserAuthentication from '../components/Order/UserAuthentication';
import InitPayment from "../components/Order/InitPayment";
import { useTranslation } from 'react-i18next';

import Layout from './Layout';
import { setError } from '../store/slices/errorSlice';
import ContactInfoSelection from '../components/Order/ContactInfoSelection';
import { ContactInfo } from '../models/ContactInfo';

const Order: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const order = useAppSelector((rootState) => rootState.order);
    const [productList, setProductList] = useState<Product[]>([]);
    const [professionalList, setProfessionalList] = useState<Professional[]>([]);
    
    useEffect(() => {
        onSlideCarousel(0);
    }, []);

    useEffect(() => {
        onSlideCarousel(order.carouselIndex);
    }, [order.carouselIndex]);

    const onSlideCarouselManual = (selectedIndex: number, e: Record<string, unknown> | null): void => {
        onSlideCarousel(selectedIndex);
    };

    const onSlideCarousel = (slideIndex: number): void => {
        window.scrollTo(0, 0);

        switch (slideIndex) {
            case CarouselStep.ProductList: // choose product
                dispatch(setLoading(true));
                ProductDataService.getAll().then((response) => {
                    setProductList(response.data);
                    dispatch(setLoading(false));
                    dispatch(setError(""));
                }).catch((e: Error) => {
                    dispatch(setError(t("misc.genericError")));
                    dispatch(setLoading(false));
                });
                break;
            case CarouselStep.ProfessionalList: // choose professional
                ProfessionalDataService.getAll().then((response) => {
                    setProfessionalList(response.data);
                    dispatch(setError(""));
                }).catch((e: Error) => {
                    dispatch(setError(t("misc.genericError")));
                });
                break;
            default:
                break;
        }
    }

    return (
        <Layout>
            <div className="container-md">
                <Carousel
                    activeIndex={order.carouselIndex}
                    onSelect={onSlideCarouselManual}
                    variant={"dark"}
                    controls={false}
                    indicators={false}
                    interval={null}
                    keyboard={false}
                    touch={false}
                    wrap={false}
                >
                    <Carousel.Item>
                        <ProductList productList={productList} />
                    </Carousel.Item>
                    <Carousel.Item>
                        <ProfessionalList professionalList={professionalList} />
                    </Carousel.Item>
                    <Carousel.Item>
                        <ReferenceYear />
                    </Carousel.Item>
                    <Carousel.Item>
                        <UserAuthentication />
                    </Carousel.Item>
                    <Carousel.Item>
                        <ContactInfoSelection />
                    </Carousel.Item>
                    <Carousel.Item>
                        <DiscountSelection />
                    </Carousel.Item>
                    <Carousel.Item>
                        <InitPayment />
                    </Carousel.Item>
                </Carousel>
            </div>
        </Layout>
    )
}

export default Order