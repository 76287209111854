import React, { useState, ChangeEvent } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Professional } from '../../models/Professional';
import OrderBackButton from './OrderBackButton';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { updateOrder } from '../../store/slices/orderSlice';
import { useTranslation } from 'react-i18next';
import { CarouselStep } from '../../utils/CarouselSteps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface Props {
    professionalList: Professional[]
}

const ProfessionalList: React.FunctionComponent<Props> = ({ professionalList }: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const order = useAppSelector((rootState) => rootState.order);
    const [professionalId, setProfessionalId] = useState<number>(0);

    const onDropDownChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        if (selectedValue) {
            let id: number = +selectedValue;
            if (id) {
                setProfessionalId(id);
            }
        }
    }

    const onProfessionalSelection = () => {
        let orderState = { ...order };
        orderState.professionalId = professionalId;
        orderState.carouselIndex = CarouselStep.ReferenceYear;
        dispatch(updateOrder(orderState));
    }

    return (
        <div className="row">
            <div className="col-12">
                <h1>{t("order.professionallist.chooseProfessional")}</h1>
                <h5 className="mb-4">{t("order.professionallist.professionalInfo")}</h5>

                <Form.Select className="w-50 mt-4" onChange={(e) => onDropDownChange(e)}>
                    <option value="0">{t("order.professionallist.defaultOption")}</option>
                    {professionalList.map((professional) => {
                        return (<option value={professional.id?.toString()} key={professional.id}>{professional.name}</option>);
                    })}
                </Form.Select>
                <OrderBackButton toIndex={0} />
                <Button variant="primary" onClick={() => onProfessionalSelection()} className="mt-4">
                    {t("misc.next")} <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>
        </div>
    )
}

export default ProfessionalList