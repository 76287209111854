import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'de',
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: process.env.REACT_APP_LOCALES_URL,
            allowMultiLoading: true,
            crossDomain: true
        },
    });

export default i18n;