import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Order } from '../../models/Order'

// Define the initial state using that type
const initialState: Order = {
    id: "", 
    carouselIndex: 0, 
    product: null, 
    professionalId: 0, 
    referenceYear: new Date().getFullYear() - 1, 
    discountCode: null,
    user: null,
    contactInfo: null
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        updateOrder: (state, action: PayloadAction<Order>) => {
            const { id, carouselIndex, product, professionalId, referenceYear, discountCode, user, contactInfo } = action.payload
            let newState = {
                ...state,
                id,
                carouselIndex,
                product,
                professionalId,
                referenceYear,
                discountCode,
                user,
                contactInfo
            }
            //console.table(newState);
            return newState;
        }
    },
})

export const { updateOrder } = orderSlice.actions
export default orderSlice.reducer